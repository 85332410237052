const vwoStorageKeyPrefix = "__vwo__"

const storageKeys = {
  APPLIED_VOUCHER: "voucher_applied",
  AUTH_JWT: "__formel_token__",
  BANNER_DISMISSED: "cms_banner-dismissed",
  BANNERS_HIDDEN: "hiddenBanners",
  CHALLENGE_HIDDEN: "challenge-hidden",
  DISABLE_RESUME_PROGRESS_REDIRECT: "DISABLE_RESUME_PROGRESS_REDIRECT",
  FIRSTNAME: "firstName",
  INITIAL_ANSWERS: "initialQAnswers",
  INITIAL_QUESTIONS: "initialQQuestions",
  INITIAL_Q_TREATMENT_GOAL_KEY: "initial_q_treatment_goal",
  NPS_DISMISSED: "dontShowNps",
  POSTHOG_USER_IDENTIFIED: "posthogUserIdentified",
  REPURCHASE_DIALOG_HIDDEN: "repurchase-dialog-hidden",
  SHIPPING_ADDRESS: "shippingAddress",
  TRACKING: "__formel_tracking__",
  TRACKING_ALL: "__formel_tracking_all__",
  VOUCHER_URL: "voucher_code",
  VWO_FEATURE_PASSWORDLESS_ACCOUNT: "FEATURE_PASSWORDLESS_ACCOUNT",
  VWO_INITIAL_QUESTIONNARE_ID: "INITIAL_QUESTIONNARE_ID",
  VWO_INITIAL_QUESTIONNARE_PROVIDER: "INITIAL_QUESTIONNARE_PROVIDER",
  VWO_QUESTIONNARE_PROVIDER: "QUESTIONNARE_PROVIDER",
  VWO_CART_SUMMARY_VARIANT: "vwoCartSummaryVariant",
  VWO_PROGRESS_BAR: "vwoProgressBar",
  VWO_RESUME_PROGRESS: "RESUME_PROGRESS",
  VWO_PERSONALIZED_PRODUCT_EXPERIENCE:
    vwoStorageKeyPrefix + "personalizedProductExperience",
}

const storageTypes = {
  LOCAL: "localStorage",
  SESSION: "sessionStorage",
}

const clientOnly = (action) =>
  typeof window === "undefined" ? () => {} : action

const getStorageKeys = () => storageKeys

const getStorageTypes = () => storageTypes

const getItem = clientOnly(({ id, storageType = storageTypes.LOCAL }) => {
  const data =
    storageType instanceof Array
      ? window[
          storageType.find((storage) => window[storage].getItem(id))
        ]?.getItem(id)
      : window[storageType].getItem(id)

  try {
    return JSON.parse(data)
  } catch {
    return data
  }
})

const setItem = clientOnly(
  ({ id, data: payload, storageType = storageTypes.LOCAL }) => {
    const data = typeof payload === "string" ? payload : JSON.stringify(payload)

    storageType instanceof Array
      ? storageType.forEach((storage) => window[storage].setItem(id, data))
      : window[storageType].setItem(id, data)
  }
)

const removeItem = clientOnly(({ id, storageType = storageTypes.LOCAL }) => {
  storageType instanceof Array
    ? storageType.forEach((storage) => window[storage].removeItem(id))
    : window[storageType].removeItem(id)
})

export {
  getStorageTypes,
  getStorageKeys,
  getItem,
  setItem,
  removeItem,
  vwoStorageKeyPrefix,
}
