import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"
import React from "react"

const GraphQLProvider = (props) => {
  const client = new ApolloClient({
    uri: process.env.GATSBY_GQL_URL,
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client} {...props} />
}

export { GraphQLProvider }
