import { apiV1 } from "./api"

const EVENT_TYPES_ENUM = {
  INITIAL_Q_PAGE_OPENED: "INITIAL_Q_PAGE_OPENED",
  INITIAL_Q_FORM_LOADED: "INITIAL_Q_FORM_LOADED",
  INITIAL_Q_QUESTION_OPENED: "INITIAL_Q_QUESTION_OPENED",
  INITIAL_Q_SUBMITTED: "INITIAL_Q_SUBMITTED",
  FINISH_INITIAL_QUESTIONNAIRE: "FINISH_INITIAL_QUESTIONNAIRE",
  SIGN_UP_OPENED: "SIGN_UP_OPENED",
  SIGN_UP_SUBMITTED: "SIGN_UP_SUBMITTED",
  ACCOUNT_CREATED: "ACCOUNT_CREATED",
  PRODUCT_SELECTION_PAGE_OPENED: "PRODUCT_SELECTION_PAGE_OPENED",
  ADD_VOUCHER_ERROR: "ADD_VOUCHER_ERROR",
  VOUCHER_ADDED: "VOUCHER_ADDED",
  PRODUCT_SELECTED: "PRODUCT_SELECTED",
  SHIPPING_PAGE_OPENED: "SHIPPING_PAGE_OPENED",
  SHIPPING_SUBMITTED: "SHIPPING_SUBMITTED",
  ADDRESS_SAVE_ERROR: "ADDRESS_SAVE_ERROR",
  SHIPPING_ADDED: "SHIPPING_ADDED",
  CART_SUMMARY_PAGE: "CART_SUMMARY_PAGE",
  PAYMENT_METHOD_CLICKED: "PAYMENT_METHOD_CLICKED",
  NATIVE_PAYMENT_SCROLL_CTA: "NATIVE_PAYMENT_SCROLL_CTA",
  NATIVE_PAYMENT_METHOD_CLICKED: "NATIVE_PAYMENT_METHOD_CLICKED",
  PAYMENT_METHOD_OPENED: "PAYMENT_METHOD_OPENED",
  PAYMENT_METHOD_ADDED: "PAYMENT_METHOD_ADDED",
  NATIVE_PAYMENT_METHOD_ADDED: "NATIVE_PAYMENT_METHOD_ADDED",
  PAYMENT_METHOD_UPDATED: "PAYMENT_METHOD_UPDATED",
  PAYMENT_INITIATED: "PAYMENT_INITIATED",
  PAYMENT_SUCCEEDED: "PAYMENT_SUCCEEDED",
  PAYMENT_ERROR: "PAYMENT_ERROR",
  ADD_NATIVE_PAYMENT_METHOD_ERROR: "ADD_NATIVE_PAYMENT_METHOD_ERROR",
  DATA_CHECK_Q_PAGE_OPENED: "DATA_CHECK_Q_PAGE_OPENED",
  DATA_CHECK_Q_FORM_LOADED: "DATA_CHECK_Q_FORM_LOADED",
  DATA_CHECK_Q_QUESTION_OPENED: "DATA_CHECK_Q_QUESTION_OPENED",
  DATA_CHECK_Q_SUBMITTED: "DATA_CHECK_Q_SUBMITTED",
  UPLOAD_IMAGES_PAGE_OPENED: "UPLOAD_IMAGES_PAGE_OPENED",
  UPLOAD_IMAGES_SELECTED: "UPLOAD_IMAGES_SELECTED",
  UPLOAD_IMAGES_SUBMITTED: "UPLOAD_IMAGES_SUBMITTED",
  UPLOAD_IMAGES_SUCCEEDED: "UPLOAD_IMAGES_SUCCEEDED",
  WAITING_AREA_OPENED: "WAITING_AREA_OPENED",
  SELECT_PAYMENT_OPTION: "SELECT_PAYMENT_OPTION",
}

const CONSENT_TOOL_USERCENTRICS = "usercentrics"
const CONSENT_TOOL_KLARO = "klaro"

const getConsentTool = () =>
  typeof window === "undefined"
    ? null
    : window.klaro
    ? CONSENT_TOOL_KLARO
    : window.UC_UI
    ? CONSENT_TOOL_USERCENTRICS
    : null

const getConsentFromKlaro = (consentServiceName) =>
  window.klaro.getManager().getConsent(consentServiceName.toLowerCase())

const getConsentFromUserCentrics = (consentServiceName) =>
  window.UC_UI.getServicesBaseInfo().some(
    (service) => service.consent.status && service.name === consentServiceName
  )

const getConsent = (consentServiceName) => {
  switch (getConsentTool()) {
    case CONSENT_TOOL_KLARO:
      return getConsentFromKlaro(consentServiceName)
    case CONSENT_TOOL_USERCENTRICS:
      return getConsentFromUserCentrics(consentServiceName)
    default:
      return false
  }
}

const consentSettings = () => {
  switch (getConsentTool()) {
    case CONSENT_TOOL_KLARO:
      return window.klaro.show(undefined, true)
    case CONSENT_TOOL_USERCENTRICS:
      return window.UC_UI.showSecondLayer()
  }
}

const trackEvent = (event) => {
  if (event.isGeneric) {
    return trackGenericEvent(event)
  }
  try {
    if (!window.dataLayer) {
      window.dataLayer = []
    }

    window.dataLayer.push({
      data: event,
      event: event.name,
    })
  } catch {}
}

const trackGenericEvent = (event) => {
  try {
    if (!window.dataLayer) {
      window.dataLayer = []
    }

    window.dataLayer.push(event)
  } catch {}
}

const sendEvent = async (event) =>
  apiV1(
    "marketing/event",
    {
      body: event,
      method: "POST",
    },
    3
  ).catch(() => {})

export {
  EVENT_TYPES_ENUM,
  getConsent,
  getConsentTool,
  consentSettings,
  trackEvent,
  trackGenericEvent,
  sendEvent,
}
