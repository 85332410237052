import { getLocaleCookie, setLocaleCookie } from "@trueskin-web/cookies"

const localeCurrencies = {
  "de-DE": {
    "de-DE": "EUR",
    "de-CH": "CHF",
  },
  "pt-BR": {
    "pt-BR": "BRL",
  },
}[process.env.GATSBY_I18N_LOCALE]

const countryDisplayNames = {
  "de-DE": {
    DE: "Deutschland",
    CH: "Schweiz",
  },
  "de-CH": {
    DE: "Deutschland",
    CH: "Schweiz",
  },
  "pt-BR": {
    BR: "Brasil",
  },
}

const locales = Object.keys(localeCurrencies)

const currencyLocales = Object.entries(localeCurrencies).reduce(
  (all, [locale, currency]) => ({
    ...all,
    [currency]: locale,
  }),
  {}
)

const countryLocales = Object.keys(localeCurrencies).reduce((all, locale) => {
  const country = locale.split("-")[1]

  return {
    ...all,
    [country]: locale,
  }
}, {})

const i18nCountryDisplayName = ({ locale } = {}) => {
  const localeName = i18nLocale({ locale })
  const country = i18nCountry({ locale })

  return countryDisplayNames[localeName][country]
}

const i18nLocale = ({ locale, currency, country } = {}) => {
  if (locale) {
    if (locales.find((iLocale) => iLocale === locale)) {
      return locale
    }
  }

  if (currency) {
    const currencyLocale = currencyLocales[currency]

    if (locales.find((iLocale) => iLocale === currencyLocale)) {
      return currencyLocale
    }
  }

  if (country) {
    const countryLocale = countryLocales[country]

    if (locales.find((iLocale) => iLocale === countryLocale)) {
      return countryLocale
    }
  }

  if (typeof window !== "undefined") {
    const cookieLocale = getLocaleCookie()

    if (locales.find((iLocale) => iLocale === cookieLocale)) {
      return cookieLocale
    } else {
      setLocaleCookie(process.env.GATSBY_I18N_LOCALE)
    }
  }

  return process.env.GATSBY_I18N_LOCALE
}

const i18nLanguage = (props) => i18nLocale(props).split("-")[0]

const i18nCountry = (props) => i18nLocale(props).split("-")[1]

const i18nCurrency = (props) => localeCurrencies[i18nLocale(props)]

const localeCountry = (locale) => {
  const [lang, code] = locale.split("-")

  return {
    lang,
    code,
    locale,
    label: i18nCountryDisplayName({ locale }),
    currency: i18nCurrency({ locale }),
  }
}

const countries = locales.map(localeCountry)

const getCountries = () => {
  const [gatsby_i18n_lang] = process.env.GATSBY_I18N_LOCALE.split("-")

  return countries.filter(({ lang }) => lang === gatsby_i18n_lang)
}

const getCountry = (isCountryCode = true) => {
  const locale = getLocaleCookie() || process.env.GATSBY_I18N_LOCALE

  const country = countries.find(
    ({ locale: countryLocale }) => countryLocale === locale
  )

  if (isCountryCode) {
    const [_lang, countryCode] = locale.split("-")

    return countryCode
  }

  return country
}

const saveCountry = (countryCode) => {
  const country = countries.find(({ code }) => code === countryCode)

  if (!country) {
    return
  }

  setLocaleCookie(country.locale)
}

export {
  locales,
  i18nLocale,
  i18nLanguage,
  i18nCurrency,
  i18nCountry,
  i18nCountryDisplayName,
  getCountries,
  getCountry,
  saveCountry,
}
