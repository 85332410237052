import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKeyPrefix = storageService.vwoStorageKeyPrefix

const getItem = (id) => storageService.getItem({ id })

const isEnabled = (id) => !!getItem(id)

const getInitialQuestionnaireId = () =>
  getItem(storageKeys.VWO_INITIAL_QUESTIONNARE_ID)

const getInitialQuestionnaireProvider = () =>
  getItem(storageKeys.VWO_INITIAL_QUESTIONNARE_PROVIDER)

const getQuestionnaireProvider = () =>
  getItem(storageKeys.VWO_QUESTIONNARE_PROVIDER)

const isPasswordlessAccountEnabled = () =>
  isEnabled(storageKeys.VWO_FEATURE_PASSWORDLESS_ACCOUNT)

const isProgressBarEnabled = () => isEnabled(storageKeys.VWO_PROGRESS_BAR)

const getCartSummaryVariant = () =>
  getItem(storageKeys.VWO_CART_SUMMARY_VARIANT)

const isResumeProgressEnabled = () => isEnabled(storageKeys.VWO_RESUME_PROGRESS)

const getPersonalizedProductVariant = () =>
  getItem(storageKeys.VWO_PERSONALIZED_PRODUCT_EXPERIENCE)

const removeStorageValues = () =>
  Object.keys(window.localStorage)
    .filter((storageKey) => storageKey.startsWith(storageKeyPrefix))
    .forEach((storageKey) => storageService.removeItem(storageKey))

export {
  getInitialQuestionnaireId,
  getInitialQuestionnaireProvider,
  getQuestionnaireProvider,
  isPasswordlessAccountEnabled,
  isProgressBarEnabled,
  getCartSummaryVariant,
  isResumeProgressEnabled,
  getPersonalizedProductVariant,
  removeStorageValues,
}
