import { removeCookie, setCookie } from "@trueskin-web/cookies"

import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKeyAnswers = storageKeys.INITIAL_ANSWERS
const storageKeyQuestions = storageKeys.INITIAL_QUESTIONS
const storageKeyTreatmentGoal = storageKeys.INITIAL_Q_TREATMENT_GOAL_KEY

const isEmpty = () =>
  !(
    storageService.getItem({ id: storageKeyAnswers }) &&
    storageService.getItem({ id: storageKeyQuestions })
  )

const getAll = () => {
  const answers = storageService.getItem({ id: storageKeyAnswers })
  const questions = storageService.getItem({ id: storageKeyQuestions })

  if (!answers || !questions) {
    return
  }

  return Object.keys(answers).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        question: questions[key],
        answer: answers[key],
      },
    }),
    {}
  )
}

const save = (data) => {
  const { answers, questions } = Object.entries(data).reduce(
    (acc, [key, { question, answer }]) => ({
      answers: {
        ...acc.answers,
        [key]: answer,
      },
      questions: {
        ...acc.questions,
        [key]: question,
      },
    }),
    {}
  )

  storageService.setItem({ id: storageKeyAnswers, data: answers })
  storageService.setItem({ id: storageKeyQuestions, data: questions })

  setCookie(storageKeyTreatmentGoal, data.TreatmentGoal.answer)
}

const remove = () => {
  storageService.removeItem({ id: storageKeyAnswers })
  storageService.removeItem({ id: storageKeyQuestions })

  removeCookie(storageKeyTreatmentGoal)
}

export { isEmpty, getAll, save, remove }
