import {
  authService,
  initialConsultationService,
  vwoService,
} from "@trueskin-web/services"

import { apiV1 } from "./api"
import * as marketingClient from "./marketing"

if (typeof window !== "undefined") {
  if (!authService.getJwt() && authService.getFirstname()) {
    authService.removeFirstname()
  }
}

function getCurrentUser() {
  if (!authService.getJwt()) {
    return Promise.resolve()
  }

  return apiV1("users/me")
}

function getIdentity() {
  if (!authService.getJwt()) {
    return Promise.resolve()
  }

  return apiV1("users/me/identity")
}

function login({ email: identifier, password }) {
  return apiV1("auth/local", {
    body: {
      identifier,
      password,
    },
  }).then(({ jwt, user }) => {
    authService.saveJwt(jwt)
    return user
  })
}

function requestLoginEmail({ email }) {
  return apiV1("users/login/email-only", {
    body: {
      email,
    },
  })
}

function emailLogin({ emailOnlyToken }) {
  return apiV1("users/login/email-only/confirm", {
    body: {
      emailOnlyToken,
    },
  }).then(({ jwt, user }) => {
    authService.saveJwt(jwt)
    return user
  })
}

function logout() {
  // TODO: proprietary app related local storage keys should be prefixed to promote automatic cleanup
  authService.removeJwt()
  authService.removeFirstname()
  initialConsultationService.remove()
  vwoService.removeStorageValues()
}

function register({
  email,
  password,
  tempToken,
  country,
  newsletterSubscription,
  campaignTracking,
  touchPoints,
}) {
  marketingClient.sendEvent({
    name: marketingClient.EVENT_TYPES_ENUM.SIGN_UP_SUBMITTED,
  })
  return apiV1("users/register", {
    body: {
      email,
      password,
      country,
      temptoken: tempToken,
      newsletterSubscription,
      campaignTracking,
      touchPoints,
    },
  }).then(({ jwt, user }) => {
    authService.saveJwt(jwt)
    return user
  })
}

function forgotPassword({ email }) {
  return apiV1("auth/forgot-password", {
    body: {
      email,
    },
  })
}

function resetPassword({ code, password, passwordConfirmation }) {
  return apiV1("auth/reset-password", {
    body: {
      code,
      password,
      passwordConfirmation,
    },
  })
}

function confirmEmail({ code: confirmation }) {
  return apiV1(`auth/email-confirmation`, {
    params: {
      confirmation,
    },
  })
}

export {
  getCurrentUser,
  getIdentity,
  login,
  requestLoginEmail,
  emailLogin,
  logout,
  register,
  forgotPassword,
  resetPassword,
  confirmEmail,
}
