import { sleep } from "./sleep"

export const conditionalFunction = async (
  condition,
  execFunction,
  retries = 20,
  interval = 50
) => {
  if (await condition()) {
    const data = await execFunction()
    return data !== undefined ? data : true
  }

  if (retries === 0) {
    return false
  }

  await sleep(interval)
  return conditionalFunction(condition, execFunction, retries - 1, interval)
}
