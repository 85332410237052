export const sizer = (sizes, size) => {
  if (typeof size === "string") {
    return sizes[size]
  }

  if (size instanceof Array) {
    const [mobile, desktop] = size

    return {
      height: [sizes[mobile].height, sizes[desktop].height],
      width: [sizes[mobile].width, sizes[desktop].width],
    }
  }
}

export const getHslaValues = (hslString) => {
  if (
    !/hsla\((\d+),\s*([\d.]+)%,\s*([\d.]+)%,\s*(\d*(?:\.\d+)?)\)/gi.test(
      hslString
    )
  ) {
    hslString = "hsla(0, 0%, 0%, 1)"
  }
  const [h, s, l, a] = hslString.match(/\d+/g).map(Number)

  return { h, s, l, a }
}

export const transparentize = (color, alpha) =>
  `hsla(${color.h}, ${color.s}%, ${color.l}%, ${alpha})`

export const shade = (color, amount) =>
  `hsla(${color.h}, ${color.s}%, ${color.l + amount}%, ${color.a})`

export const colors = (color) => {
  const hslaValues = getHslaValues(color)
  const { l: lightness } = hslaValues
  const isBlackOrWhite = lightness === 0 || lightness === 100
  const isDark = lightness < 50

  const lighterColor = isBlackOrWhite
    ? transparentize(hslaValues, 0.2)
    : shade(hslaValues, 5)
  const darkerColor = isBlackOrWhite
    ? transparentize(hslaValues, 0.4)
    : shade(hslaValues, -10)
  const foregroundColor = isBlackOrWhite
    ? color
    : isDark
    ? "background"
    : "text"
  const disabledColor = lightness === 100 ? darkerColor : "disabled"

  return {
    color,
    borderColor: color,
    "&:hover": {
      bg: lighterColor,
      borderColor: lighterColor,
      color: foregroundColor,
    },
    "&:focus, &.focus": {
      bg: isBlackOrWhite ? lighterColor : color,
      borderColor: isBlackOrWhite ? color : darkerColor,
      color: foregroundColor,
    },
    "&:active, &.active": {
      bg: darkerColor,
      borderColor: darkerColor,
      color: foregroundColor,
    },
    "&:disabled, &[aria-disabled=true]": {
      color: disabledColor,
      borderColor: disabledColor,
    },
    "&:after": {
      bg: color,
    },
  }
}
