import { checkIcon } from "@trueskin-web/theme/icons/checkIcon"
import { giftIcon } from "@trueskin-web/theme/icons/giftIcon"
import { prizeFilledIcon } from "@trueskin-web/theme/icons/prizeFilledIcon"
import { timerIcon } from "@trueskin-web/theme/icons/timerIcon"
import i18next from "@trueskin-web/translations"

import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKey = storageKeys.CHALLENGE_HIDDEN

const statusTypes = {
  NOT_ENROLLED: "NOT_ENROLLED",
  ENROLLED: "ENROLLED",
  COMPLETED_WON: "COMPLETED_WON",
  COMPLETED_LOST: "COMPLETED_LOST",
}

const statusDetails = {
  [statusTypes.NOT_ENROLLED]: {
    description: i18next
      .t("SkinChallenge.status.not_enrolled.description", {
        returnObjects: true,
      })
      .map((text, index) => ({
        text,
        icon: [timerIcon, giftIcon, prizeFilledIcon][index],
      })),
    title: () => i18next.t("SkinChallenge.status.not_enrolled.title"),
  },
  get [statusTypes.ENROLLED]() {
    return this[statusTypes.NOT_ENROLLED]
  },
  [statusTypes.COMPLETED_WON]: {
    description: i18next
      .t("SkinChallenge.status.completed.won.description", {
        returnObjects: true,
      })
      .map((text) => ({
        text,
        icon: checkIcon,
        iconColor: "primary",
      })),
    title: (firstName) =>
      i18next.t("SkinChallenge.status.completed.won.title", { firstName }),
  },
  [statusTypes.COMPLETED_LOST]: {
    description: i18next
      .t("SkinChallenge.status.completed.lost.description", {
        returnObjects: true,
      })
      .map((text) => ({ text })),
    title: (firstName) =>
      i18next.t("SkinChallenge.status.completed.lost.title", { firstName }),
  },
}

const getChallengeStatuses = (isDetailed) =>
  isDetailed ? statusDetails : statusTypes

const getChallengeStatus = (challenge) =>
  challenge.status === "COMPLETED"
    ? parseInt(challenge.progress.totalSubmission) <= 24
      ? "COMPLETED_LOST"
      : "COMPLETED_WON"
    : challenge.status

const hideChallenge = () => {
  storageService.setItem({ id: storageKey, data: new Date() })
}

const unhideChallenge = () => {
  storageService.removeItem({ id: storageKey })
}

const isChallengeHidden = () => {
  const timestamp = storageService.getItem({ id: storageKey })
  if (timestamp) {
    return true
  }

  return false
}

const isChallengeAvailable = (status) =>
  [
    statusTypes.COMPLETED_WON,
    statusTypes.COMPLETED_LOST,
    statusTypes.NOT_ENROLLED,
  ].includes(status)

export {
  getChallengeStatuses,
  getChallengeStatus,
  hideChallenge,
  unhideChallenge,
  isChallengeHidden,
  isChallengeAvailable,
}
