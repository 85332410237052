import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKeyJwt = storageKeys.AUTH_JWT
const storageKeyFirstname = storageKeys.FIRSTNAME

const getJwt = () => storageService.getItem({ id: storageKeyJwt })

const getFirstname = () => storageService.getItem({ id: storageKeyFirstname })

const saveJwt = (jwt) => {
  storageService.setItem({ id: storageKeyJwt, data: jwt })
}

const saveFirstname = (firstname) => {
  storageService.setItem({ id: storageKeyFirstname, data: firstname })
}

const removeJwt = () => {
  storageService.removeItem({ id: storageKeyJwt })
}

const removeFirstname = () => {
  storageService.removeItem({ id: storageKeyFirstname })
}

export {
  getJwt,
  getFirstname,
  saveJwt,
  saveFirstname,
  removeJwt,
  removeFirstname,
}
