import i18next from "i18next"
import ICU from "i18next-icu"
import { initReactI18next } from "react-i18next"

import { i18nLanguage } from "@trueskin-web/locales"

import {
  deCommonTranslations,
  dePortalRoutes,
  dePortalTranslations,
  deSiteRoutes,
  deSiteTranslations,
} from "./de"
import {
  enCommonTranslations,
  enPortalRoutes,
  enPortalTranslations,
  enSiteRoutes,
  enSiteTranslations,
} from "./en"
import {
  ptCommonTranslations,
  ptPortalRoutes,
  ptPortalTranslations,
  ptSiteRoutes,
  ptSiteTranslations,
} from "./pt"

const fallbackLocale = "en-GB"

const en = {
  translation: {
    ...enCommonTranslations,
    ...enPortalTranslations,
    ...enSiteTranslations,
    ...enPortalRoutes,
    ...enSiteRoutes,
  },
}

const de = {
  translation: {
    ...deCommonTranslations,
    ...dePortalTranslations,
    ...deSiteTranslations,
    ...dePortalRoutes,
    ...deSiteRoutes,
  },
}

const pt = {
  translation: {
    ...ptCommonTranslations,
    ...ptPortalTranslations,
    ...ptSiteTranslations,
    ...ptPortalRoutes,
    ...ptSiteRoutes,
  },
}

i18next
  .use(initReactI18next)
  .use(ICU)
  .init({
    resources: { en, de, pt },
    lng: i18nLanguage(),
    fallbackLng: i18nLanguage({ locale: fallbackLocale }),
  })

export default i18next
