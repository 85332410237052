const DefaultTheme = {
  breakpoints: ["54em", "74em"],
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 80, 128, 264],
  sizes: {
    container: 1216,
    element: 1120,
  },
  colors: {
    text: "hsla(30, 32%, 9%, 1)",
    textLight: "hsla(0, 1%, 70%, 1)",
    textDark: "hsla(0, 0%, 55%, 1)",
    background: "hsla(0, 0%, 100%, 1)",
    primary: "hsla(173, 100%, 27%, 1)",
    primaryLighter: "hsla(171, 39%, 87%, 1)",
    primaryLight: "hsla(173, 100%, 32%, 1)",
    primaryDark: "hsla(173, 100%, 18%, 1)",
    attention: "hsla(6, 85%, 61%, 1)",
    notification: "hsla(221, 80%, 74%, 1)",
    disabled: "hsla(30, 32%, 9%, 0.25)",
    grey: "hsla(0, 0%, 88%, 1)",
    lightGray: "hsla(0, 0%, 95%, 1)",
    lightRose: "hsla(342, 71%, 97%, 1)",
    lightYellow: "hsla(44, 85%, 95%, 1)",
    lightGreen: "hsla(171, 39%, 87%, 1)",
    blue: "hsla(221, 53%, 89%, 1)",
    lightBlue: "hsla(215, 100%, 96%, 1)",
    lightDark: "hsla(0, 5%, 23%, 1)",
    goldDark: "hsla(42, 94%, 53%, 1)",
    trustpilotGreen: "hsla(160, 100%, 36%, 1)",
    rose: "hsla(337, 43%, 75%, 1)",
    darkRose: "hsla(344, 59%, 87%, 1)",
    rawUmber: "hsla(29, 18%, 39%, 1)",
    grullo: "hsla(29, 26%, 58%, 1)",
    tan: "hsla(30, 45%, 71%, 1)",
    lightOrange: "hsla(30, 75%, 80%, 1)",
    lighterOrange: "hsla(30, 88%, 83%, 1)",
    white: "hsla(0, 0%, 100%, 1)",
    black: "hsla(0, 0%, 0%, 1)",
    klarna: "#FFB3C7",
    klarnaLight: "#FFBFD0",
    klarnaDark: "#F0A5b7",
  },
  fonts: {
    body: "'Maison Neue', system-ui, sans-serif",
    monospace: "'Maison Neue Mono', Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    smallerHeading: 1.33,
    heading: 1.125,
  },
  letterSpacings: {
    body: "normal",
    caps: "0.2em",
  },
  borders: {
    "1px": "1px solid",
  },
  text: {
    caps: {
      textTransform: "uppercase",
      letterSpacing: "caps",
    },
  },
  layout: {
    sectionPadding: {
      py: [9, 10],
    },
    sectionPaddingSmall: {
      py: [6, 10],
    },
    sectionMargin: {
      my: [9, 10],
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontSize: 2,
      lineHeight: "body",
    },
  },
}

export default DefaultTheme
