import {
  Identify,
  getUserId,
  identify,
  init,
  reset,
  setUserId,
  track,
} from "@amplitude/analytics-browser"

const amplitudeApiKey = process.env.GATSBY_AMPLITUDE_API_KEY
const isProduction = process.env.GATSBY_DEPLOY_ENVIRONMENT === "production"
const logLevels = {
  WARN: "Warn",
  DEBUG: "Debug",
}

const isBrowser = () => typeof window !== "undefined"

const logErrorMessage = (error) =>
  console.error("Logging analytics event error:", error)

if (isBrowser() && amplitudeApiKey) {
  try {
    init(amplitudeApiKey, null, {
      logLevel: isProduction ? logLevels.WARN : logLevels.DEBUG,
    })
  } catch (error) {
    logErrorMessage(error)
  }
}

const trackEvent = (eventName, data) => {
  try {
    track(eventName, data)
  } catch (error) {
    logErrorMessage(error)
  }
}

const identifyUser = (id) => {
  try {
    setUserId(id)
  } catch (error) {
    logErrorMessage(error)
  }
}

const setUserProperties = (props) => {
  try {
    const propertiesKeys = Object.keys(props)

    propertiesKeys.forEach((key) => {
      const event = new Identify()
      event.set(key, props[key])

      identify(event)
    })
  } catch (error) {
    logErrorMessage(error)
  }
}

const isUserIdentified = () => {
  try {
    return getUserId()
  } catch (error) {
    logErrorMessage(error)
  }
}

const removeIdentifier = () => {
  try {
    reset()
  } catch (error) {
    logErrorMessage(error)
  }
}

export {
  trackEvent,
  identifyUser,
  setUserProperties,
  removeIdentifier,
  isUserIdentified,
}
