import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKey = storageKeys.BANNER_DISMISSED

const storageTypes = storageService.getStorageTypes()

const isBannerDismissed = () =>
  storageService.getItem({
    id: storageKey,
    storageType: storageTypes.SESSION,
  })

const dismissBanner = () =>
  storageService.setItem({
    id: storageKey,
    data: true,
    storageType: storageTypes.SESSION,
  })

export { isBannerDismissed, dismissBanner }
