/** @jsx jsx */
import { createContext, useContext, useEffect, useMemo, useState } from "react"

import { useMutation, useQuery, useQueryClient } from "@trueskin-web/apis"
import { authClient, marketingClient } from "@trueskin-web/apis"
import { tofuGqlClient, useGqlMutation } from "@trueskin-web/gql"
import {
  amplitudeService,
  authService,
  posthogService,
} from "@trueskin-web/services"
import { jsx } from "@trueskin-web/theme"

const AuthContext = createContext()

function AuthProvider(props) {
  const queryClient = useQueryClient()

  const {
    status,
    isLoading,
    isError,
    data: user,
    error,
  } = useQuery("currentUser", authClient.getCurrentUser)

  const subscription = user?.subscription

  const { isLoading: isUserIdentityLoading, data: userIdentity } = useQuery(
    "userIdentity",
    authClient.getIdentity
  )

  const extractIdentity = ({ id, firstName, lastName }) => ({
    id,
    firstName,
    lastName,
  })

  const { mutateAsync: login } = useMutation(authClient.login, {
    onSuccess: (data) => {
      queryClient.setQueryData("currentUser", data)
      queryClient.setQueryData("userIdentity", extractIdentity(data))
    },
  })

  const { mutateAsync: emailLogin } = useMutation(authClient.emailLogin, {
    onSuccess: (data) => {
      queryClient.setQueryData("currentUser", data)
      queryClient.setQueryData("userIdentity", extractIdentity(data))
    },
  })

  const [tofuSync] = useGqlMutation(tofuGqlClient.importTofuUser, {
    onCompleted: ({ importTofuUser: { jwt, user } }) => {
      authService.saveJwt(jwt)

      queryClient.setQueryData("currentUser", user)
      queryClient.setQueryData("userIdentity", extractIdentity(user))
    },
  })

  const { mutateAsync: logout } = useMutation(authClient.logout, {
    onSuccess: () => {
      amplitudeService.removeIdentifier()
      queryClient.clear()
    },
  })

  const { mutateAsync: register } = useMutation(authClient.register, {
    onSuccess: (data) => {
      queryClient.setQueryData("userIdentity", extractIdentity(data))
      queryClient.refetchQueries("currentUser")
    },
  })

  const [canSeeReferral, setCanSeeReferral] = useState(false)
  const [renewalDateDialog, setRenewalDateDialog] = useState(false)

  const isSubscriptionRecurringActive =
    subscription?.type === "recurring" &&
    (subscription.status === "paid" || subscription.status === "created") &&
    !subscription.cancellationScheduled &&
    !subscription.pauseOn

  useEffect(() => {
    if (userIdentity) {
      authService.saveFirstname(userIdentity.firstName || "Anonymous")
    }
  }, [userIdentity])

  useEffect(() => {
    setCanSeeReferral(isSubscriptionRecurringActive)
  }, [isSubscriptionRecurringActive])

  const value = useMemo(
    () => ({
      status,
      isLoading,
      isError,
      user,
      userIdentity,
      isUserIdentityLoading,
      error,
      login,
      emailLogin,
      tofuSync,
      logout,
      register,
      canSeeReferral,
      renewalDateDialog,
      setRenewalDateDialog,
    }),
    [
      status,
      isLoading,
      isError,
      user,
      userIdentity,
      isUserIdentityLoading,
      error,
      login,
      emailLogin,
      tofuSync,
      logout,
      register,
      canSeeReferral,
      renewalDateDialog,
      setRenewalDateDialog,
    ]
  )

  return <AuthContext.Provider value={value} {...props} />
}

function trackUser(context) {
  if (context?.user?._id) {
    if (marketingClient.getConsent("Google Analytics")) {
      if (!window.dataLayer) {
        window.dataLayer = []
      }

      const hasAlreadySentUserId = window.dataLayer.some(
        ({ event: storedEvent, userId }) =>
          storedEvent === "set-user-id" && userId === context.user._id
      )

      if (!hasAlreadySentUserId) {
        marketingClient.trackGenericEvent({
          event: "set-user-id",
          userId: context.user._id,
        })
      }
    }

    if (window.posthog) {
      if (!posthogService.isUserIdentified()) {
        window.posthog.identify(
          context?.user?._id,
          {},
          { email: context?.user?.email }
        )

        posthogService.identifyUser()
      }
    }

    if (!amplitudeService.isUserIdentified()) {
      amplitudeService.identifyUser(context.user._id)
    }
  }
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  trackUser(context)
  return context
}

export { AuthProvider, useAuth }
