/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const giftIcon  = {
  path: (
    <Fragment>
      <rect
        x="4.97656"
        y="10.0703"
        width="17.2319"
        height="11.9298"
        fill="#90ABEC"
      />
      <rect x="2.5" y="11.5" width="17" height="9" stroke="currentColor" />
      <rect
        x="1.5"
        y="5.61621"
        width="18.883"
        height="5.62765"
        stroke="currentColor"
      />
      <rect x="9.5" y="5.5" width="3" height="15" stroke="currentColor" />
      <path
        d="M10.9941 5.11721C10.3314 3.79168 8.50874 1.62491 7.01755 1.14062C4.97634 0.477696 3.8667 2.2923 4.36649 3.79168C5.02925 5.77998 8.28384 5.5 8.99951 5.5"
        stroke="currentColor"
      />
      <path
        d="M10.876 5.11721C11.5387 3.79168 13.3614 1.62491 14.8526 1.14062C16.8938 0.477696 18.0034 2.2923 17.5036 3.79168C16.8409 5.77998 13.7157 5.5 13 5.5"
        stroke="currentColor"
      />
    </Fragment>
  ),
  viewBox: "0 0 24 24",
  fill: "none",
}