import { differenceInYears } from "date-fns"
import get from "lodash.get"
import { isPossiblePhoneNumber } from "react-phone-number-input"

import { i18nCountry } from "@trueskin-web/locales"
import i18next from "@trueskin-web/translations"

const AGE_LIMIT = 14

export const required = (value) => {
  const errorMessage = i18next.t("Generic.errors.required")

  if (typeof value === "string") {
    return Boolean(value.trim()) ? undefined : errorMessage
  }

  return value !== undefined && value !== null
    ? undefined
    : i18next.t("Generic.errors.required")
}

export const mustBeEmail = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (re.test(value) || !value) {
    return undefined
  } else {
    return i18next.t("Generic.errors.invalidEmail")
  }
}

export const mustBeTheSame = (fieldToMatch) => (value, allValues) =>
  value === allValues[fieldToMatch]
    ? undefined
    : i18next.t("Generic.errors.mustBeTheSame", { value: fieldToMatch })

export const minLength = (min) => (value) =>
  !value || value.length >= min
    ? undefined
    : i18next.t("Generic.errors.minLength", { value: min })

export const maxLength = (max) => (value) =>
  !value || value.length <= max
    ? undefined
    : i18next.t("Generic.errors.maxLength", { value: max })

export const mustBeTrue = (value) =>
  value === true ? undefined : i18next.t("Generic.errors.mandatoryField")

const postalCodeLength = {
  DE: 5,
  CH: 4,
  BR: 8,
}

export const postalCodeRegex = {
  DE: /^\d{5}$/,
  CH: /^\d{4}$/,
  BR: /^\s?(\d\s?){5}-?\s?(\d\s?){3}$/,
}

export const mustBePostalCodeByCountry =
  (countryFieldPath) => (value, allValues) => {
    const countryCode = countryFieldPath
      ? get(allValues, countryFieldPath)
      : i18nCountry()
    const numbers = value.replace(new RegExp("[^0-9]+", "ig"), "")

    const requiredLength = postalCodeLength[countryCode]

    if (!requiredLength) {
      return undefined
    }

    if (numbers.length !== requiredLength) {
      return i18next.t("Generic.errors.requiredLength", {
        value: requiredLength,
      })
    }

    if (!postalCodeRegex[countryCode].test(value)) {
      return i18next.t("Generic.errors.invalidPostalCode")
    }
  }

export const mustContainNumber = (value) =>
  /\d/.test(value) ? undefined : i18next.t("Generic.errors.mustContainNumber")

export const mustNotContainNumbers = (value) =>
  !/\d/.test(value)
    ? undefined
    : i18next.t("Generic.errors.mustNotContainNumbers")

export const mustContainOnlyNumbers = (value) =>
  !value || /^\d+$/.test(value)
    ? undefined
    : i18next.t("Generic.errors.onlyNumbersAllowed")

export const mustBeValidYear = (value) =>
  !value || /^(?:(?:19|20)[0-9]{2})$/.test(value)
    ? undefined
    : i18next.t("Generic.errors.invalidYear")

export const mustBeValidDay = (day, month, year) =>
  !/^(0?[1-9]|[12][0-9]|3[01])$/.test(day) ||
  new Date(year, month, day).getMonth() !== month
    ? i18next.t("Generic.errors.invalidDate")
    : undefined

export const mustBeValidBirthday = (value) =>
  differenceInYears(new Date(), new Date(value)) < AGE_LIMIT
    ? i18next.t("Generic.errors.invalidAge")
    : undefined

export const composeValidators =
  (...validators) =>
  (value, allValues) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues),
      undefined
    )

export const mustBeCPF = (value) => {
  const err = i18next.t("Start.Shipping.socialSecurityNumberIsNotValid")

  // some value is set
  if (!value) {
    return err
  }

  // has "000.000.000-00" format
  if (!/^([-\.\s]?(\d{3})){3}[-\.\s]?(\d{2})$/.test(value)) {
    return err
  }

  const cpfNumber = value.replace(/[\.\-\/]+/g, "")

  // checking for length && same digits
  if (
    cpfNumber.length !== 11 ||
    cpfNumber.split("").every((i, _, arr) => i === arr[0])
  ) {
    return err
  }

  const startNumber = 9

  const sumNumbers = (number) => {
    if (number > 10) {
      return
    }

    const sum = [...Array(number).keys()].reduce(
      (accum, current) =>
        accum + parseInt(cpfNumber.charAt(current)) * (number + 1 - current),
      0
    )

    let rest = 11 - (sum % 11)

    if (rest === 10 || rest === 11) {
      rest = 0
    }

    if (rest !== parseInt(cpfNumber.charAt(number))) {
      return err
    }

    number++

    return sumNumbers(number)
  }

  return sumNumbers(startNumber)
}

export const mustBePhoneNumber = (value) =>
  isPossiblePhoneNumber(value)
    ? undefined
    : i18next.t("Generic.errors.invalidPhoneNumber")
