import { useState } from "react"

import i18next, { Trans } from "@trueskin-web/translations"

const ERRORS_MESSAGES = {
  "Identifier or password invalid.": i18next.t(
    "Generic.errors.emailOrPasswordInvalid"
  ),
  "invalid.currentPassword": i18next.t("Generic.errors.invalidCurrentPassword"),
  "Username already taken.": i18next.t("Generic.errors.usernameAlreadyTaken"),
  "This email does not exist.": i18next.t("Generic.errors.nonExistingEmail"),
  "Failed to fetch": i18next.t("Generic.errors.failedTofetch"),
  "Payment collection failed. Reason: (3001) Insufficient funds.": i18next.t(
    "Generic.errors.paymentFailed"
  ),
  "Insufficient funds": i18next.t("Generic.errors.insufficientFunds"),
  "email.alreadyTaken": i18next.t("Generic.errors.emailAlreadyTaken"),
  "token.expired": i18next.t("Generic.errors.tokenExpired"),
  "renewalDate.cannotEqualTodayOrEarlier": i18next.t(
    "Generic.errors.renewalDateCannotEqualTodayOrEarlier"
  ),
  "renewalDate.cannotEqualSameRenewalDate": i18next.t(
    "Generic.errors.cannotEqualSameRenewalDate"
  ),
  "Incorrect code provided.": i18next.t("Generic.errors.incorrectCode"),
}

const findError = (object, target) => {
  if (!object || typeof object !== "object") {
    return null
  }

  if (object.hasOwnProperty(target) && typeof object[target] !== "object") {
    return object
  }

  if (Array.isArray(object)) {
    return object.reduce((accum, curr) => {
      accum = findError(curr, target)
      if (accum) {
        return accum
      }
    }, null)
  }

  return Object.keys(object).reduce((accum, curr) => {
    if (typeof object[curr] === "object") {
      accum = findError(object[curr], target)
    }
    if (accum !== null && typeof accum !== "undefined") {
      return accum
    }
  }, null)
}

const getErrorMessageByKey = (errorKey) =>
  i18next.exists(errorKey) && i18next.t(errorKey)

const getErrorMessage = (error) => {
  const unknownErrorMessage = "Unknown error"

  if (!error) {
    return unknownErrorMessage
  }

  if (typeof error === "object") {
    error = findError(error, "message") || {}
  }

  return (
    getErrorMessageByKey(error.id) ||
    ERRORS_MESSAGES[error.message] ||
    error.message ||
    unknownErrorMessage
  )
}

const ButtonAction = ({ element: Element, onClick, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Element
      {...props}
      onClick={() => {
        setIsLoading(true)
        onClick()
      }}
      isLoading={isLoading}
    />
  )
}

const getVoucherError = (data) => (
  <Trans
    i18nKey={`Voucher.error.${data?.message}`}
    defaults={i18next.t("Voucher.error.DEFAULT")}
    values={data}
    components={{
      action:
        data?.action && data?.components.action ? (
          <ButtonAction
            element={data.components.action}
            onClick={data.action}
            variant="link"
            size="xsm"
            noPadding
          />
        ) : (
          <span />
        ),
    }}
  />
)

export { getErrorMessageByKey, getErrorMessage, getVoucherError }
