/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const timerIcon  = {
  path: (
    <Fragment>
      <circle cx="20.0625" cy="21.125" r="14.875" fill="#F2CCD6" />
      <circle
        cx="15.875"
        cy="15.875"
        r="14.875"
        stroke="#1D160F"
        strokeDasharray="2 2"
      />
      <path
        d="M15.875 30.75C17.8284 30.75 19.7627 30.3652 21.5674 29.6177C23.3721 28.8702 25.0119 27.7745 26.3932 26.3932C27.7745 25.0119 28.8702 23.3721 29.6177 21.5674C30.3652 19.7627 30.75 17.8284 30.75 15.875C30.75 13.9216 30.3652 11.9873 29.6177 10.1826C28.8702 8.37786 27.7745 6.73806 26.3932 5.35679C25.0119 3.97551 23.3721 2.87983 21.5674 2.13229C19.7627 1.38475 17.8284 0.999999 15.875 0.999999"
        stroke="#1D160F"
      />
      <path
        d="M10.5625 15.9897L14.2223 19.3798L21.1875 10.5625"
        stroke="#1D160F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Fragment>
  ),
  viewBox: "0 0 35 36",
  fill: "none",
}